import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Row, UncontrolledCollapse } from 'reactstrap'
import { Contract, DefaultPlans, PaymentModality } from '../../../interface/interface';
import { getActiveDefaultPlans, getAllDefaultPlans } from '../../../controllers/DefaultPlansController';
import SignatureInput from '../contract-inputs/SignatureInput';
import { NumericFormat } from 'react-number-format';
import { CARD_BODY, CARD_TITLE } from '../../../services/classService';
import { genericErrorAlert } from '../../../services/alertService';

interface Props {

    paymentModality: PaymentModality
    setPaymentModality: (paymentModality: PaymentModality) => void
    contract: Contract
    setContract: (contract: Contract) => void

}

const ProgramInformation: React.FC<Props> = ({ paymentModality, setPaymentModality, contract, setContract }) => {

    const [defaultPlans, setDefaultPlans] = useState<Array<DefaultPlans>>([]);
    const [currentPlan, setCurrentPlan] = useState<string>("Personalizado")

    const defaulPaymentModality: PaymentModality = {
        plan_type: "Personalizado",
        total: 0,
        enrollment: 0,
        balance: 0,
        installments_number:1,
        installments_value: 0
    };

    useEffect(() => {
        loadDefaultPlans();
    }, [])

    const handleInputSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCurrentPlan(value);

        if (value !== "Personalizado") {
            const planSelected = getPlanSelected(parseInt(value))
            setPaymentModality(planSelected)
        } else {

            setPaymentModality(defaulPaymentModality)
        }
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (currentPlan !== "Personalizado") {
            return
        }


        // Eliminar puntos y comas y convertirlo en un número decimal
        const numericValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));

        if (!isNaN(numericValue)) {
            setPaymentModality({
                ...paymentModality, [name]: numericValue
            });
        }

    };


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;


        if (currentPlan !== "Personalizado") {
            return
        }

        const numericValue = parseFloat(value);

        if (!isNaN(numericValue)) {
            setPaymentModality({
                ...paymentModality, [name]: numericValue
            });
        }


    };


    const handleUserSignature = (signature: string) => {

        setContract({
            ...contract, user_signature: signature
        });

    }

    const handleHolderSignature = (signature: string) => {

        setContract({
            ...contract, holder_signature: signature
        });

    }


    const getPlanSelected = (id: number): PaymentModality => {

        const planSelected = defaultPlans.filter(plan => plan.id === id)[0]

        const { plan_type, total, enrollment, balance, installments_number, installments_value } = planSelected;

        return {
            plan_type: plan_type,
            total: total,
            enrollment: enrollment,
            balance: balance,
            installments_number: installments_number,
            installments_value: installments_value
        }
    }

    const loadDefaultPlans = async () => {
        try {
            const defaultPlansValues: any = await getActiveDefaultPlans();
            setDefaultPlans(defaultPlansValues.data.plans)

        } catch (error) {
            genericErrorAlert("Error al cargar las modalidades de pago","Recargar la pagina")
            console.log(error + "")
        }
    }

    return (
        <Card className={CARD_BODY + 'm-3 contract-backgroud'}>
            <CardHeader tag="h4" className={CARD_TITLE} >
                <b>Información del Programa</b>
            </CardHeader>
            <CardBody>
                <CardTitle className='underline text-center tex-white' lg={12}>
                    <Label className='text-white' tag="h4"><b>PRIME ENGLISH PROGRAM</b></Label>
                </CardTitle>
                <Row>
                    <Col>
                        <Alert color="warning" className='m-3 text-center' tag="h4">
                            <b> PLATAFORMA INTERACTIVA MOBILE LEARNING PARA EL APRENDIZAJE DEL IDIOMA INGLÉS CON PROGRAMACIÓN NEUROLINGÜÍSTICA</b>
                        </Alert>
                    </Col>
                </Row>

                <CardTitle className='underline text-center' lg={12}>
                    <Label className='text-white' tag="h4"><b>MODALIDAD DE PAGO</b></Label>
                </CardTitle>

                <Row>
                    <Col lg={12}>

                        <FormGroup className="form-outline mb-4">
                            <Input type="select" name="default_plans" value={currentPlan} onChange={handleInputSelect}>

                                <option value="Personalizado">Personalizado</option>

                                {defaultPlans.map((plan, index) => (
                                    <option key={index} value={plan.id}>{plan.plan_type} ( {plan.installments_number} - Pagos)</option>
                                ))}
                            </Input>

                            <FormText>
                                Tipo de plan (*)
                            </FormText>

                        </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>

                        <FormGroup>

                            <InputGroup>
                                <InputGroupText>$</InputGroupText>
                                <NumericFormat className='form-control'
                                    name='total'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    value={paymentModality.total + ",00"}
                                    onChange={handleNumberChange}
                                    decimalScale={2}
                                    allowNegative={false}
                                    disabled={currentPlan !== "Personalizado"}
                                    required />
                            </InputGroup>

                            <FormText>
                                Total (*)
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                        <FormGroup>

                            <InputGroup>
                                <InputGroupText>$</InputGroupText>
                                <NumericFormat className='form-control'
                                    name='enrollment'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    value={paymentModality.enrollment + ",00"}
                                    onChange={handleNumberChange}
                                    decimalScale={2}
                                    allowNegative={false}
                                    disabled={currentPlan !== "Personalizado"}
                                    required />
                            </InputGroup>

                            <FormText>
                                Valor Matricula (*)
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>


                        <FormGroup>

                            <InputGroup>
                                <InputGroupText>$</InputGroupText>
                                <NumericFormat className='form-control'
                                    name='balance'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    value={paymentModality.balance + ",00"}
                                    onChange={handleNumberChange}
                                    decimalScale={2}
                                    allowNegative={false}
                                    disabled={currentPlan !== "Personalizado"}
                                    required />
                            </InputGroup>


                            <FormText>
                                Saldo (*)
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                        <FormGroup>
                            <Input name='installments_number'
                                type='number'
                                min={1}
                                value={paymentModality.installments_number}
                                disabled={currentPlan !== "Personalizado"}
                                onChange={handleInputChange}
                                required />
                            <FormText>
                                N° de cuotas (*)
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                        <FormGroup >

                            <InputGroup>
                                <InputGroupText>$</InputGroupText>
                                <NumericFormat className='form-control'
                                    name='installments_value'
                                    type='text'
                                    thousandSeparator="."
                                    decimalSeparator=','
                                    decimalScale={2}
                                    value={paymentModality.installments_value + ",00"}
                                    onChange={handleNumberChange}
                                    allowNegative={false}
                                    disabled={currentPlan !== "Personalizado"}
                                    required />
                            </InputGroup>
                            <FormText>
                                Valor de cuotas (*)
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>

            <Row>
                <Col className="text-center">
                    <CardTitle className="underline" lg={12}>
                        <Label className='text-white' tag="h4"><b>CONDICIONES GENERALES DEL CONTRATO</b></Label>
                    </CardTitle>

                    <UncontrolledCollapse toggler="#toggler" className='text-white'>
                        <p className="contract-details px-3">
                            <b>UNO (1) CENTRO DE ESTUDIOS ALTERNATIVOS CEA GROUP SAS</b>, COMPAÑÍA ALTAMENTE ESPECIALIZADA 
                            EN LA ENSEÑANZA Y CAPACITACIÓN DEL IDIOMA INGLÉS, A TRAVÉS DE SU PLATAFORMA INTERACTIVA 
                            MOBILE LEARNING <b>NBP INGLES CON LOS MEJORES</b>, BRINDARÁ ASESORÍA DURANTE DOCE MESES A PARTIR DE 
                            LA FECHA DE LA FIRMA DE ESTE CONTRATO POR TRES HORAS SEMANALES DISTRIBUIDAS EN TRES DÍAS A 
                            LA SEMANA EN HORARIO DE LUNES A VIERNES DE 7 AM A 7PM JORNADA CONTINUA Y SÁBADOS DE 8 AM A 2 PM, 
                            DE ACUERDO A DISPONIBILIDAD, LAS CUALES DEBEN SER RESERVADAS CON 48 HORAS DE ANTICIPACIÓN EN 
                            NUESTRO SISTEMA <b>CEA MANAGEMENT</b> A TRAVÉS DE LOS MEDIOS DESTINADOS PARA TAL FIN. 
                            LAS ASESORÍAS PUEDEN SER VIRTUALES, PRESENCIALES O MIXTAS PARA QUE EL TITULAR PUEDA 
                            COMPLETAR LOS NIVELES DE LOS QUE CONSTA EL PROGRAMA. <b>DOS (2) CEA GROUP SAS</b> Y SU PROGRAMA <b>NBP 
                            INGLÉS CON LOS MEJORES</b> DISPONE DE GRUPOS REDUCIDOS POR CLASE. <b>TRES (3)</b> CUANDO EL TITULAR  
                            ESTE IMPOSIBILITADO PARA ASISTIR A SUS ASESORÍAS DURANTE UN PERIODO SUPERIOR A UNA SEMANA O 
                            MÁS DEBERÁ COMUNICARLO POR ESCRITO CON UNA SEMANA DE ANTICIPACIÓN PARA EVITAR QUE ESE TIEMPO 
                            SEA DESCONTADO DE SU PERIODO DE DOCE MESES, ESTO PUEDE HACERLO POR MÁXIMO UN MES Y 
                            POR UNA UNICA VEZ. <b>CUATRO (4) CEA GROUP SAS</b> Y SU PROGRAMA <b>NBP INGLÉS CON LOS MEJORES</b> GARANTIZA 
                            EL APRENDIZAJE Y DOMINIO DEL IDIOMA INGLÉS SIEMPRE Y CUANDO EL TITULAR CUMPLA CON LOS 
                            REQUISITOS QUE SE EXIGEN PARA TAL FIN Y QUE SE EXPLICAN EN LA INDUCCIÓN, ADEMÁS DE LOS SIGUIENTES: 
                            <b>A)</b> INICIAR Y SEGUIR EL PROGRAMA CON ESTRICTA REGULARIDAD. <b>B)</b> PRÁCTICAR CON LA 
                            PLATAFORMA CADA LECCIÓN MÍNIMO TREINTA MINUTOS POR DÍA Y ANTES DE SOLICITAR LA 
                            CLASE EN VIVO,REALIZAR LOS EJERCICIOS DE CADA LECCIÓN. <b>C)</b> APROVECHAR TODAS LAS 
                            CLASES EXTRACURRICULARES PROGRAMADAS. <b>CINCO (5)</b> EL TITULAR RECIBIRÁ DOS 
                            INDUCCIONES, UNA PARA EL CONOCIMIENTO DE LA METODOLOGÍA Y EL MANEJO DE LA 
                            PLATAFORMA Y LA SEGUNADA PARA USO DE HERRAMIENTAS DE LA PNL (PROGRAMACIÓN NEUROLINGUÍSTICA). 
                            <b>SEIS (6) ESTÁ CONTEMPLADA LA ROTACIÓN DE PROFESORES</b>, LA ROTACIÓN DE COMPAÑEROS DE CLASE Y LA 
                            MEZCLA DE LECCIONES DE ACUERDO AL NIVEL, LO CUAL PERMITE EL DESARROLLO EFECTIVO DEL PROGRAMA.  
                            <b>SIETE (7)</b> EL PROGRAMA <b>NBP INGLÉS CON LOS MEJORES</b> HA SIDO DESARROLLADO ESPECÍFICA Y 
                            EXCLUSIVAMENTE PARA <b>CEA GROUP SAS</b> POR EXPERTOS CON PROFUNDO CONOCIMIENTO DEL IDIOMA INGLÉS 
                            Y LA PNL. (PROGRAMACIÓN NEUROLINGUÍSTICA). <b>OCHO (8)</b> ESTE CONTRATO TIENE CARÁCTER IRREVOCABLE, 
                            POR LO TANTO NO SE HACEN DEVOLUCIONES NI REINTEGROS DE DINERO POR NINGÚN CONCEPTO PASADOS 
                            CINCO DIÁS DESDE LA INSCRIPCIÓN. <b>NUEVE (9)</b> EL PAGO DE LAS MENSUALIDADES ES SUCESIVO Y 
                            CONSECUTIVO Y EL NO PAGO DE LAS MISMAS LE DA DERECHO A <b>CEA GROUP SAS</b> A DAR POR TERMINADO 
                            ESTE CONTRATO Y BLOQUEAR LOS ACCESOS AL SISTEMA, COBRAR EL PAGO DE LA PENALIDAD QUE CORRESPONDE A 
                            UNA MENSUALIDAD O AL VALOR DE LA CANCELACIÓN VIGENTE AL MOMENTO DE PRESENTARSE ESTA SITUACION DE NO PAGO O 
                            SOLICITUD DE CANCELACIÓN POR PARTE DEL ESTUDIANTE. <b>DIEZ (10)</b> LA PÉRDIDA DE LAS CLAVES DE 
                            ACCESO AL SISTEMA GENERARÁ CARGOS ADMINISTRATIVOS. <b>ONCE (11)</b> AL FINALIZAR EL <b>PROGRAMA NBP 
                            INGLÉS CON LOS MEJORES CEA GROUP SAS</b> LE EXPEDIRÁ UNA CERTIFICACIÓN COMO INTERPRETE DEL IDIOMA 
                            INGLÉS SEGÚN LO ESTIPULADO POR EL MARCO COMÚN EUROPEO. <b>DOCE (12)</b> DECLARO QUE HE RECIBIDO COPIA 
                            FIEL DE ESTE DOCUMENTO Y ESTOY DE ACUERDO Y ACEPTO LOS TÉRMINOS SEÑALADOS EN ÉL.</p>
                    </UncontrolledCollapse>
                    <Button
                        className='col-12 text-white'
                        color=""
                        id="toggler"
                        style={{
                            marginBottom: '1rem'
                        }}
                    >
                        <b>Ver condiciones</b>

                    </Button>

                    <Row className='d-flex d-flex justify-content-center align-items-center p-3'>
                        <Col sm={12} md={6}>
                            <Label tag="h5" className='text-white'><b>FIRMA DEL EJECUTIVO CEA S.A.S</b></Label>
                            <SignatureInput signature={contract.user_signature} setSignature={handleUserSignature}></SignatureInput>
                        </Col>
                        <Col sm={12} md={6}>
                            <Label tag="h5" className='text-white'><b>FIRMA DEL TITULAR</b></Label>
                            <SignatureInput signature={contract.holder_signature} setSignature={handleHolderSignature}></SignatureInput>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default ProgramInformation